import React, { useState } from 'react';
import { Box, Button, Stack } from '@mui/material';

import styles from './MintButton.module.css';

import MintPng from '../../assets/images/Mint-quantity.png';
import CrossBrand from '../../assets/images/Crossmint_Brand.png';
import Minus from '../../assets/images/Minus.png';
import Plus from '../../assets/images/Plus.png';


const MintButton = ({ variant = "button", caption, clickFunc, quantity, incQuantity, decQuantity }) => {
    const isMintButton = variant === "button";
    const [btnText, setBtnText] = useState(caption);
    const [isMinting, setIsMinting] = useState(false);

    const handleClick = async () => {
        if(isMinting) {
            return;
        }
        const oldCaption = btnText;
        if(oldCaption === "Mint")
            setBtnText("Minting....");
        else
            setBtnText("CrossMinting...");
        setIsMinting(true);
        
        await clickFunc();

        if(oldCaption === "Mint")
            setBtnText("Mint");
        else 
            setBtnText('CrossMint');
        setIsMinting(false);
    }

    return (
        <Box className={isMintButton ? styles.buttonWrapper : styles.inputWrapper}>
        {isMintButton ? (
            <Box sx={{ position: "relative" }} onClick={handleClick} disabled={isMinting}>
                <img src={MintPng} alt="" className={styles.background} />
                {caption === "CrossMint" ? (
                  <img src={CrossBrand} alt="cross" className={styles.crossImage} />
                ) : (
                  <Box className={styles.buttonCaption}> { btnText } </Box>
                )}
            </Box>
        ) : (
            <Stack sx={{flex: 1}} direction="row" alignItems="center">
                <Button variant="contained" className={styles.button} onClick={decQuantity}>
                    <img src={Minus} alt="" className={styles.buttonIcon}/>
                </Button>
                <Box className={styles.quantityText}>
                    { quantity }
                </Box>
                <Button variant="contained" className={styles.button} onClick={incQuantity}>
                    <img src={Plus} alt="" className={styles.buttonIcon}/>
                </Button>
            </Stack>
        )}
        </Box>
    );
};

export default MintButton;
