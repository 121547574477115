import React, { useState, useEffect } from "react";
import { Grid, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";
import { useAccount } from 'wagmi';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { setNftQuantity } from "../../slices/nftData";
import { getMoonizensNFTMinterContract, getEthersSigner, fromBigNum, toBigNum, getBalance } from "../../services/web3.service";
import Navigation from '../../components/Navigation/Navigation';
import MessageConsole from '../../components/MessageConsole/MessageConsole';
import StateConsole from '../../components/StateConsole/StateConsole';
import MintButton from '../../components/MintButton/MintButton';
import { CrossmintPayButton } from '@crossmint/client-sdk-react-ui';

import styles from './../Mint/Mint.module.css';
import Star from '../../assets/images/Star.png';
import Hero from '../../assets/images/Mint.png';

import avatar from '../../assets/images/MintTemp.jpg';

const nfts = [
    { label: 'First', image: avatar },
]

const MintSetting = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { address, isConnected } = useAccount();

    const isCrossMint =  useSelector((state) => state.nftData.isCrossMint);

    const isTablet = useMediaQuery(theme.breakpoints.down("lg"));
    const messageText = `Select the number of Moonizens Genesis to Mint and begin the Mymbocar process.`;
    
    const [quantity, setQuantity] = useState(0);

    useEffect(() => {
        if(!isConnected && !isCrossMint) {
            navigate('/');
        }
    }, [isConnected]);

    const incQuantity = () => {
        if(quantity < 100) {
            setQuantity(quantity + 1);
            dispatch(setNftQuantity(quantity + 1));
        }
    }

    const decQuantity = () => {
        if(quantity > 0) {
            setQuantity(quantity - 1);
            dispatch(setNftQuantity(quantity - 1));
        }
    }

    const clickMint = async () => {
        if(quantity === 0) {
            Swal.fire({
                icon: 'warning',
                title: 'You have to set NFT count!',
            }); 
            return;
        } else {
            const signer = await getEthersSigner();
            const nftMinterContract = await getMoonizensNFTMinterContract(signer);
            const canMint = await nftMinterContract.canMint(address, quantity);            
            if(canMint) {
                const mintPrice = fromBigNum(await nftMinterContract.getPrice(quantity));
                const walleBalance = await getBalance(address);

                if(walleBalance < mintPrice) {
                    Swal.fire({
                        icon: 'warning',
                        title: "You don't have enough balance in your wallet!",
                    }); 
                    return;
                } else {
                    try {
                        let tx = await nftMinterContract.mintTo(address, quantity, {value : toBigNum(mintPrice)});
                        await tx.wait();
                        navigate("/result");
                    } catch (error) {
                        Swal.fire({
                            icon: 'error',
                            title: "Network Error!",
                        }); 
                    }
                }
            } else {
                Swal.fire({
                    icon: 'error',
                    title: "You can not mint NFTs!",
                }); 
            }
        }
    }
    return (
        <div className={styles.pageWrapper}>
            <Navigation/>
            <div className={styles.contentWrapper}>
                <img src={Star} alt="star" className={styles.backgroundStar}/>
                {isTablet ? (
                    <>
                        <img src={Hero} alt="hero" className={styles.heroImage}/>
                        <MessageConsole text={messageText} isFirstConsole={false}/>
                        <div className={styles.mintWrapper}>
                            <StateConsole variant="image" nfts={nfts}/>
                            <Grid container spacing={1}>
                                <Grid item xs={6}>
                                    {
                                        !isCrossMint ? <MintButton caption="Mint" clickFunc={clickMint}/> : 
                                        <CrossmintPayButton
                                            className={styles.crossMintButton}
                                            collectionTitle="Test MembershipNft2"
                                            collectionDescription="Testing"
                                            collectionPhoto="null"
                                            clientId="b673268c-8c62-4940-8460-d03a992e3852"
                                            mintConfig={{
                                                type:"erc-721",
                                                totalPrice:(quantity * 165).toString(),
                                                _quantity: quantity.toString()
                                            }}
                                            environment="production"
                                        />
                                    }
                                </Grid>
                                <Grid item xs={6}>
                                    <MintButton variant="quantity" quantity={quantity} incQuantity={incQuantity} decQuantity={decQuantity}/>
                                </Grid>
                            </Grid>
                        </div>
                    </>
                ) : (
                    <>
                        <div className={styles.content}>
                            <img src={Hero} alt="hero" className={styles.heroImage}/>
                            <div className={styles.mintWrapper}>
                                <StateConsole variant="image" nfts={nfts}/>
                                <Grid container spacing={1}>
                                    <Grid item xs={6}>
                                    {
                                        !isCrossMint ? <MintButton caption="Mint" clickFunc={clickMint}/> : 
                                        <CrossmintPayButton
                                            className={styles.crossMintButton}
                                            collectionTitle="Test MembershipNft2"
                                            collectionDescription="Testing"
                                            collectionPhoto="null"
                                            clientId="b673268c-8c62-4940-8460-d03a992e3852"
                                            mintConfig={{
                                                type:"erc-721",
                                                totalPrice:(quantity * 165).toString(),
                                                _quantity: quantity.toString()
                                            }}
                                            environment="production"
                                        />
                                    }
                                    </Grid>
                                    <Grid item xs={6}>
                                        <MintButton variant="quantity" quantity={quantity} incQuantity={incQuantity} decQuantity={decQuantity} />
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                        <MessageConsole text={messageText} isFirstConsole={false}/>
                    </>
                )}
            </div>
        </div>
    );
};

export default MintSetting;
