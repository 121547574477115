import MoonizensNFT from "../abis/MoonizensNFT.json";
import MoonizensNFTMinter from "../abis/MoonizensNFTMinter.json";
import { ethers, providers } from "ethers";
import { getWalletClient } from '@wagmi/core';
import { polygon } from '@wagmi/core/chains';

export const getMoonizensNFTContract = async (signer = null) => {
    
    const provider = new ethers.providers.JsonRpcProvider('https://rpc-mainnet.maticvigil.com');
    const finalSigner = signer !== null ?  signer : provider;

    const contract = new ethers.Contract(MoonizensNFT.address, MoonizensNFT.abi, finalSigner);
    return contract;
}

export const getMoonizensNFTMinterContract = async (signer = null) => {
    
    const provider = new ethers.providers.JsonRpcProvider('https://rpc-mainnet.maticvigil.com');
    const finalSigner = signer !== null ?  signer : provider;

    const contract = new ethers.Contract(MoonizensNFTMinter.address, MoonizensNFTMinter.abi, finalSigner);
    return contract;
}

export function walletClientToSigner(walletClient) {
    const { account, chain, transport } = walletClient;
    const network = {
      chainId: chain.id,
      name: chain.name,
      ensAddress: chain.contracts?.ensRegistry?.address,
    }
    const provider = new providers.Web3Provider(transport, network);
    const signer = provider.getSigner(account.address);
    return signer;
}

export async function getEthersSigner() {
    const walletClient = await getWalletClient({
        chainId: polygon.id,
    });
    if (!walletClient) {
        return null;
    }
    return walletClientToSigner(walletClient);
}

export const toBigNum = (value, decimal = 18) => {
    return ethers.utils.parseUnits(String(value), decimal);
}

export const fromBigNum = (value, decimal = 18) => {
    let result = parseFloat(ethers.utils.formatUnits(value, decimal));
    return result;
}

export const getBalance = async (address) => {
    const provider = new ethers.providers.JsonRpcProvider('https://rpc-mainnet.maticvigil.com');
    const balance = await provider.getBalance(address);
    return parseFloat(ethers.utils.formatUnits(balance, 18));
}


