import React, { useState, useEffect } from "react";
import { Grid, Typography, useMediaQuery, Stack } from '@mui/material';

import { useTheme } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useAccount } from 'wagmi';
//import { getMoonizensNFTContract, fromBigNum } from "../../services/web3.service";

import Navigation from '../../components/Navigation/Navigation';
import MessageConsole from '../../components/MessageConsole/MessageConsole';
import StateConsole from '../../components/StateConsole/StateConsole';

import styles from './../Mint/Mint.module.css';
import Star from '../../assets/images/Star.png';
import Avatar from '../../assets/images/MintTemp.jpg';
import Hero from '../../assets/images/Mint.png';

const MintResult = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const { isConnected } = useAccount();

    const isCrossMint =  useSelector((state) => state.nftData.isCrossMint);
    const nftQuantity = useSelector((state) => state.nftData.nftQuantity);

    const isTablet = useMediaQuery(theme.breakpoints.down("lg"));
    const messageText = "Access Control List: Verified.\n You are now part of the Moonizens Genesis NFT collection.\n The journey awaits"
    const [nfts, setNfts] = useState([]);
    
    useEffect(() => {
        if(!isConnected && !isCrossMint) {
            navigate('/');
        }
    }, [isConnected])

    useEffect(() => {
        const chainEnabled = async () => {
            await fetchNFTData();
        }

        chainEnabled();
    }, []);


    const fetchNFTData = async () => {
        
        let _nfts = [];
        for(let i = 0; i < nftQuantity; i ++) {
            _nfts.push({label: i, image: Avatar});
        }
        setNfts(_nfts);
    }
    
    return (
        <div className={styles.pageWrapper}>
            <Navigation/>
            <div className={styles.contentWrapper}>
                <img src={Star} alt="star" className={styles.backgroundStar}/>
                {isTablet ? (
                    <>
                        <img src={Hero} alt="hero" className={styles.heroImage}/>
                        <MessageConsole text={messageText} isFirstConsole={false}/>
                        <div className={styles.mintWrapper}>
                            <StateConsole variant="image" nfts={nfts}/>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Typography>{nfts.length} NFT Minted</Typography>
                                </Grid>
                               
                            </Grid>
                        </div>
                    </>
                ) : (
                    <>
                        <div className={styles.content}>
                            <img src={Hero} alt="hero" className={styles.heroImage}/>
                            <div className={styles.mintWrapper}>
                                <StateConsole variant="image" nfts={nfts}/>
                                <Stack direction={"row"} justifyContent={'center'} alignItems={"center"}>
                                    <Typography variant="h4">{nfts.length} NFT Minted</Typography>
                                </Stack>
                                
                            </div>
                        </div>
                        <MessageConsole text={messageText} isFirstConsole={false}/>
                    </>
                )}
            </div>
        </div>
    );
};

export default MintResult;
