import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { StyledEngineProvider, ThemeProvider } from '@mui/material';
import { Web3ReactProvider } from '@web3-react/core';
import getLibrary from './config/getLibrary';

import { Provider } from "react-redux";
import store from './store';
import useCustomTheme from './hooks/useCustomTheme';

import Mint from "./pages/Mint/Mint";
import MintSetting from './pages/MintSetting/MintSetting';
import MintResult from './pages/MintResult/MintResult';
import Loading from './pages/Loading/Loading';
import './App.css';

const App = () => {
  const { customTheme } = useCustomTheme();

  return (

        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={customTheme}>
            <Provider store={store}>
            
              <Web3ReactProvider getLibrary={getLibrary}>
                <BrowserRouter>
                  <Routes>
                    <Route exact path='/' element={<Mint />} />
                    <Route exact path='/setting' element={<MintSetting />} />
                    <Route exact path='/loading' element={<Loading />} />
                    <Route exact path='/result' element={<MintResult />} />

                  </Routes>
                </BrowserRouter>
              </Web3ReactProvider>
            
            </Provider>
          </ThemeProvider>
        </StyledEngineProvider>
    
  );
}

export default App;
