import React from 'react';
import ReactAudioPlayer from 'react-audio-player';

import './Logo.css';

import Audio from '../../assets/sounds/loop.mp3';
import Logo800 from '../../assets/images/logo.png';
import Logo500 from '../../assets/images/logo-500.png';

const Logo = ({ size = 'large' }) => {

  return (
    <>
      <ReactAudioPlayer
          src={Audio}
          autoPlay={true}
          loop={true}
          controls={false}
      />
      <img className={`logo ${size}`} src={Logo800} loading='lazy' alt=''
        srcSet={`${Logo500} 500w, ${Logo800} 800w`}
      />
    </>
  );
};

export default Logo;
