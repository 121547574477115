import { configureStore } from '@reduxjs/toolkit'
import nftDataReducer from "./slices/nftData";

const reducer = {
    nftData: nftDataReducer,
}

const store = configureStore({
    reducer: reducer,
    devTools: true,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
})

export default store;