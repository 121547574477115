import React from 'react';
import { Box } from '@mui/material';
import { NavLink } from 'react-router-dom';
import './Navigation.css';
import Logo from '../Logo/Logo';
import { ConnectButton } from '@rainbow-me/rainbowkit';

const Navigation = () => {
    return (
        <Box className="navbar">
            <Box className="container nav-container">
                <NavLink to='/' end className="brand w-nav-brand">
                    <Logo size="small" />
                </NavLink>
            </Box>
            <ConnectButton 
                showBalance={false}
                accountStatus={{
                    smallScreen: 'avatar',
                    largeScreen: 'full',
                }}
                chainStatus="icon"
            />
        </Box>
        
    );
};

export default Navigation;
