import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    nftQuantity: 0,
    isCrossMint: false,
};

const nftDataSlice = createSlice({
    name: "nftData",
    initialState,
    reducers: {
        setNftQuantity: (state, action) => {
            state.nftQuantity = action.payload;
        },
        setCrossMint: (state, action) => {
            state.isCrossMint = action.payload;
        },
    },    
});

export const { setNftQuantity, setCrossMint } = nftDataSlice.actions;
export default nftDataSlice.reducer;
