import { createTheme } from "@mui/material";

const useCustomTheme = () => {
  const customTheme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 576,
        md: 768,
        lg: 992,
        xl: 1200,
        xxl: 1400,
      },
      step: 10
    }
  });

  return {customTheme};
};

export default useCustomTheme;
