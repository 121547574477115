import React, {useEffect, useState} from 'react';
import { Grid, useMediaQuery, Slide } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";
import { useAccount } from 'wagmi';
import { useDispatch } from 'react-redux';
import { setCrossMint } from '../../slices/nftData';
import { getMoonizensNFTContract, getEthersSigner, fromBigNum } from '../../services/web3.service';
import DisclaimerModal from '../../components/DisclaimerModal/DisclaimerModal';
import Navigation from '../../components/Navigation/Navigation';
import MessageConsole from '../../components/MessageConsole/MessageConsole';
import StateConsole from '../../components/StateConsole/StateConsole';
import MintButton from '../../components/MintButton/MintButton';
import Swal from 'sweetalert2';
import styles from './Mint.module.css';
import Star from '../../assets/images/Star.png';
import Hero from '../../assets/images/Spook.png';

const Transition = React.forwardRef((props, ref) => {
    return <Slide direction="down" ref={ref} {...props} />;
});

const Mint = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { isConnected } = useAccount();

    const isTablet = useMediaQuery(theme.breakpoints.down('lg'));
    const [statText, setStatText] = useState('');

    const [isFirstConsole, setIsFirstConsole] = useState(true);
    const [consoleText, setConsoleText] = useState("Connect Your Wallet!");

    const [openDisclaimer, setOpenDisclaimer] = useState(true);

    useEffect(() => {
        const chainEnabled = async () => {
            await fetchNFTData();
        }
        chainEnabled();
    }, []);

    useEffect(()=> {
        if(isConnected) {
            setIsFirstConsole(false);
            setConsoleText("Welcome to the Moonizens Genesis Mint.\nExperience Mymbocar as Chuid and Human merge becoming ... a Moonizen.");
        } else {
            setIsFirstConsole(true);
            setConsoleText("Connect Your Wallet!");
        }
    }, [isConnected]);

    const fetchNFTData = async () => {
        const signer = await getEthersSigner();
        const nftContract = await getMoonizensNFTContract(signer);
        const maxSupply = await nftContract.maxSupply();
        const totalSupply = await nftContract.totalSupply();
        const leftSupply = fromBigNum((maxSupply - totalSupply), 0);
        
        setStatText("TOTAL SUPPLY: " + maxSupply + " \nSUPPLY LEFT: " + leftSupply + "\nPRICE: 165 MATIC");
    }

    const handleCloseDisclaimer = () => {
        setOpenDisclaimer(false);
    }

    const handleMint = ( isCrossMint ) => {

        if(isCrossMint) {
            dispatch(setCrossMint(isCrossMint));
            navigate("/loading");
        } else {
            if(isConnected) {
                dispatch(setCrossMint(isCrossMint));
                navigate("/loading");
            } else {
                Swal.fire({
                    icon: 'warning',
                    title: 'Please connect your wallet!',
                }); 
            }
        }
    }

    return (
        <div className={styles.pageWrapper}>
            <Navigation/>
            <div className={styles.contentWrapper}>
                <img src={Star} alt="star" className={styles.backgroundStar}/>
                {isTablet ? (
                    <>
                        <img src={Hero} alt="hero" className={styles.heroImage}/>
                        <MessageConsole text={consoleText} isFirstConsole={isFirstConsole}/>
                        <div className={styles.mintWrapper}>
                            <StateConsole text={statText} />
                            <Grid container spacing={1}>
                                <Grid item xs={6}>
                                    <MintButton caption="Mint" clickFunc={() => handleMint(false)}/>
                                </Grid>
                                <Grid item xs={6}>
                                    <MintButton caption="CrossMint" clickFunc={() => handleMint(true)}/>
                                </Grid>
                            </Grid>
                        </div>
                    </>
                ) : (
                    <>
                        <div className={styles.content}>
                        <img src={Hero} alt="hero" className={styles.heroImage}/>
                            <div className={styles.mintWrapper}>
                                <StateConsole text={statText} />
                                <Grid container spacing={1}>
                                    <Grid item xs={6}>
                                        <MintButton caption="Mint" clickFunc={() => handleMint(false)}/>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <MintButton caption="CrossMint" clickFunc={() => handleMint(true)}/>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                        <MessageConsole text={consoleText} isFirstConsole={isFirstConsole}/>
                    </>
                )}
            </div>

            <DisclaimerModal open={openDisclaimer} transition={Transition} handleClose={handleCloseDisclaimer}/>

        </div>
    );
};

export default Mint;
